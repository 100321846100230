<template>
  <div>
    <create-or-select
      :allow-create="false"
      :items="singleValuedAndSecureVariables"
      label="variable_name"
      value="variable_id"
      :current_select="langVarId"
      :placeholder="__('Please select a variable')"
      style="width: 100%;"
      @change="handleLanguageVariableSelectionChange"
    />
    <div class="info">
      <div class="info-wrapper">
        <!-- eslint-disable-next-line -->
        {{ __("This variable will override the primary ASR Language. For supported language codes, ") }}
        <el-link
          type="info"
          class="info-link"
          target="blank"
          :underline="false"
          :href="docInfoUrl"
        >
          {{ __("click here") }}
        </el-link>
      </div>
    </div>
  </div>
</template>

<script>
import CreateOrSelect from "@/views/build/callflow/components/node-type-forms/components/CreateOrSelect";
import { mapGetters } from "vuex";
import _ from "lodash";
const LANGUAGE_SUPPORT_DOC_URL =
  "https://docs.studioportal.io/Content/studio-nodes/languages/_ch-studio-asrlanguages.htm";
export default {
  components: {
    CreateOrSelect
  },
  props: {
    value: {
      required: true,
      type: Number
    }
  },
  data() {
    return {
      langVarId: "",
      docInfoUrl: LANGUAGE_SUPPORT_DOC_URL
    };
  },

  computed: {
    ...mapGetters("variables", {
      singleValuedAndSecureVariables: "singleValuedAndSecureVariables"
    })
  },

  methods: {
    handleLanguageVariableSelectionChange(option) {
      let val = option.value !== -1 ? option.value : null;
      this.$emit("lang-var-changed", val);
    }
  },

  watch: {
    value: {
      immediate: true,
      deep: true,
      handler: function(newVal, oldVal) {
        if (newVal !== -1 && !_.isEqual(newVal, oldVal)) {
          this.langVarId = _.cloneDeep(newVal);
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/element-variables.scss";
.info-wrapper {
  margin-left: 2px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.info-link {
  margin-left: 2px;
  font-size: 13px;
}

.info {
  color: #696969;
  display: flex;
  align-items: center;
  line-height: 1.5;
  font-size: 13px;
  margin-bottom: 2px;
  margin-top: 5px;
  letter-spacing: 0.005rem;
}
</style>
